import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Box,
  BoxProps,
  chakra,
  Divider,
  Flex,
  Heading,
  Image,
  LinkBox,
  LinkOverlay,
  Text,
} from "@chakra-ui/react"
import * as React from "react"
import { ChevronRightIcon } from "@chakra-ui/icons"
import { graphql, Link as GatsbyLink, PageProps } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"

import Layout from "../components/layout"
import SocialSharing, { MobileShareMenu } from "../components/socialSharing"
import { IBlogPost } from "../components/blogs"
import { clamp, useEmbedly } from "../utils"
import SEO from "../components/CoreUI/SEO"

interface TemplateProps extends PageProps {
  data: {
    blog: IBlogPost
    recentBlogs: {
      nodes: IBlogPost[]
    }
  }
}

interface UniformBoxProps extends BoxProps {
  children: React.ReactNode
}

function UniformBox(props: UniformBoxProps): JSX.Element {
  return <Box maxW="95vw" mx="auto" w="800px" {...props} />
}

function BlogPostTemplate({ data, location }: TemplateProps): JSX.Element {
  const [relPubDate, setRelPubDate] = React.useState<string>(``)
  const [relUpdateDate, setRelUpdateDate] = React.useState<string>(``)
  const [relatedPubDates, setRelatedPubDates] = React.useState<string[] | []>(
    []
  )

  const { blog, recentBlogs } = data

  let { html } = blog.content.childMarkdownRemark
  if (html.includes(`class="embedly-card"`)) {
    html = html.replace(/embedType=async/g, `embedType=iframe`)
    useEmbedly()
  }

  React.useEffect(() => {
    dayjs.extend(relativeTime)

    const publishedFromNow = dayjs(blog.datePublished).fromNow()
    setRelPubDate(publishedFromNow)

    if (blog.datePublished !== blog.updatedAt) {
      const updatedFromNow = dayjs(blog.updatedAt).fromNow()
      setRelUpdateDate(updatedFromNow)
    }

    const relatedBlogPubDates = recentBlogs.nodes.reduce((acc, cur) => {
      const fromNow = dayjs(cur.datePublished).fromNow()
      return [...acc, fromNow]
    }, [])
    setRelatedPubDates(relatedBlogPubDates)
  }, [blog.datePublished, blog.updatedAt, recentBlogs.nodes])

  return (
    <Layout>
      <SEO
        meta={{
          description: blog.content.childMarkdownRemark.excerpt,
          image: blog.featuredImage.gatsbyImageData.images.fallback.src,
          imageAlt: blog.featuredImage.description,
        }}
        title={`${blog.title} | Sundog Homes`}
        url={location.href}
      />
      <Box
        d={{ base: `none`, lg: `block` }}
        right="0"
        pos="fixed"
        top="calc(4.5rem + 100px)"
      >
        <SocialSharing
          mediaUrl={blog.featuredImage.gatsbyImageData.images.fallback.src}
          title={blog.title}
          url={location.href}
        />
      </Box>
      <MobileShareMenu
        mediaUrl={blog.featuredImage.gatsbyImageData.images.fallback.src}
        title={blog.title}
        url={location.href}
      />
      <UniformBox mt={6} py={2}>
        <Breadcrumb separator={<ChevronRightIcon />}>
          <BreadcrumbItem>
            <BreadcrumbLink as={GatsbyLink} to="/blog/">
              Blog
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink
              maxW={[`calc(95vw - 70px)`, , `730px`]}
              overflowX="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {blog.title}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </UniformBox>
      <UniformBox mt={4}>
        <GatsbyImage
          alt={blog.featuredImage.description}
          image={blog.featuredImage.gatsbyImageData}
          objectFit="scale-down"
          style={{ maxHeight: `50vh`, width: `100%` }}
        />
      </UniformBox>
      <UniformBox mt={[2, 2, 4]}>
        <Heading
          as="h1"
          fontWeight="extrabold"
          fontSize={clamp.pixels([24, 36], [320, 800])}
          maxW="100%"
        >
          {blog.title}
        </Heading>
        <Flex align="center" justify="space-between" mt={2} w="100%">
          <Box fontSize={[`xs`, `md`]}>
            <Text color="gray.500">
              Published {relPubDate}
              {relUpdateDate && ` | Updated ${relUpdateDate}`}
            </Text>
          </Box>
          <Box fontSize={[`xs`, `md`]}>
            <Text color="gray.700">
              {blog.content.childMarkdownRemark.wordCount.words} words |{` `}
              {blog.content.childMarkdownRemark.timeToRead} min read
            </Text>
          </Box>
        </Flex>
      </UniformBox>
      <UniformBox mt={6}>
        <chakra.div
          className="blog-content"
          dangerouslySetInnerHTML={{
            __html: html,
          }}
          mb={12}
        />
      </UniformBox>
      <Divider />
      <Box bg="gray.50" w="100vw">
        <Box maxW="95vw" mx="auto" py={12} w="1000px">
          <Heading as="h5" fontSize="2xl" textAlign="center">
            More Sundog Blogs
          </Heading>
          <Flex
            align="start"
            flexDir="row"
            flexWrap="wrap"
            justify="space-between"
            mt={6}
            w="100%"
          >
            {recentBlogs.nodes.map(
              (blog: IBlogPost, index: number): JSX.Element => (
                <Box
                  d={{ sm: index > 1 ? `none` : `block`, lg: `block` }}
                  key={index}
                  w={{ base: `100%`, sm: `49%`, lg: `32%` }}
                >
                  <LinkBox
                    as={Flex}
                    border="1px"
                    borderColor="gray.200"
                    flexDir="column"
                    justify="space-evenly"
                    key={index}
                    my={2}
                    p={3}
                    rounded="lg"
                    shadow="md"
                    w="100%"
                  >
                    <Image
                      alt={blog.featuredImage.description}
                      as={GatsbyImage}
                      h={{ base: `250px`, sm: `150px`, md: `185px` }}
                      image={blog.featuredImage.gatsbyImageData}
                      rounded="lg"
                    />
                    <LinkOverlay
                      as={GatsbyLink}
                      my={1}
                      to={`/blog/${blog.slug}/`}
                      _hover={{ color: `red.500`, textDecoration: `underline` }}
                    >
                      <Text fontSize="lg" fontWeight="bold" isTruncated>
                        {blog.title}
                      </Text>
                    </LinkOverlay>
                    <Text noOfLines={3}>
                      {blog.content.childMarkdownRemark.excerpt}
                    </Text>
                    <Text color="gray.500" mt={2} textAlign="right">
                      Published {relatedPubDates[index]}
                    </Text>
                  </LinkBox>
                </Box>
              )
            )}
          </Flex>
        </Box>
      </Box>
    </Layout>
  )
}

export default BlogPostTemplate

export const query = graphql`
  query($slug: String!) {
    blog: contentfulBlogPost(slug: { eq: $slug }) {
      ...BlogPost
    }
    recentBlogs: allContentfulBlogPost(
      filter: { owner: { eq: "Sundog Homes" }, slug: { ne: $slug } }
      limit: 3
      sort: { fields: datePublished, order: DESC }
    ) {
      nodes {
        ...BlogPostExcerpt
      }
    }
  }
`
